<template>
  <div class="stepItem">
    <div class="step">
      <div v-for="index in max" :key="index" :class="['item', {'select': index <= step}]">
        {{ index }}
      </div>
    </div>
    <div class="progress">
      <mt-progress :value="progress" :barHeight="8">
        <div slot="end">{{ progress }}%</div>
      </mt-progress>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepItem',
  props: {
    max: {
      type: Number,
      default: 5
    },
    step: {
      type: Number,
      default: 1,
    }
  },
  data() {
    return {

    }
  },
  computed: {
    progress() {
      return this.step / this.max * 100
    }
  }
}
</script>

<style lang="scss" scoped>
.stepItem {
  .step {
    margin: 10px;
    text-align: center;
    .item {
      position: relative;
      width: 25px;
      height: 25px;
      border-radius: 25px;
      background-color: #e3e2e2;
      display: inline-block;
      text-align: center;
      line-height: 25px;
      margin-left: 10px;
      font-size: 12px;
      &.select {
        background-color: #820ECA;
        color: white;
        &::before {
          content: '';
          background-color: #820ECA;
          height: 2px;
        }
      }
      &::before {
        content: '';
        position: absolute;
        background-color: #e3e2e2;
        height: 1px;
        width: 10px;
        left: -10px;
        top: 12px;
      }
      &:first-child {
        margin-left: 0px;
        &::before {
          display: none;
        }
      }
    }
  }
  .progress {
    padding: 0px 20px 10px 20px;
  }
}
</style>