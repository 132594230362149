var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relationshipauth"},[_c('mt-header',{attrs:{"title":_vm.$t('relationshipauth')}},[_c('router-link',{attrs:{"slot":"left","to":""},slot:"left"},[_c('mt-button',{attrs:{"icon":"back"},on:{"click":function($event){return _vm.$router.back(-1)}}},[_vm._v(_vm._s(_vm.$t('back')))])],1),_c('call-phone',{attrs:{"slot":"right"},slot:"right"})],1),_c('step-item',{attrs:{"step":3}}),_c('div',{staticClass:"content"},[_vm._l((_vm.form.contactExpansion),function(item,index){return _c('div',{key:index,staticClass:"box"},[(index >= 2)?_c('img',{staticClass:"delete",attrs:{"src":require("../../assets/delete.png")},on:{"click":function($event){return _vm.form.contactExpansion.splice(index, 1)}}}):_vm._e(),(_vm.Enum.contactRelation.map(e => e.code).indexOf(item.contactRelationCode) >= 0 || index == 0)?_c('div',{staticClass:"separator"},[_vm._v(_vm._s(_vm.$t('relativeContact')))]):_c('div',{staticClass:"separator"},[_vm._v(_vm._s(_vm.$t('otherContact')))]),_c('div',[_c('mt-field',{attrs:{"label":_vm.$t('relationship'),"placeholder":_vm.$t('relationshipPlaceholder'),"readonly":"","state":index >= 2? null: _vm.contactRelationCodeState(item.contactRelationCode)},nativeOn:{"click":function($event){_vm.onPopupPicker(index == 0? _vm.Enum.contactRelation: _vm.relationshipAll, (e) => {
            item.contactRelationCode = e.code;
            _vm.BEHAVIOR_ADD({
              id: `P03_C${_vm.FillZero((index * 3) + 1)}_S_RELATIONCODE`,
              newValue: item.contactRelationCode
            })
          })}},model:{value:((_vm.relationshipAll.filter(e => e.code == item.contactRelationCode)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.relationshipAll.filter(e => e.code == item.contactRelationCode)[0]||{}), "name", $$v)},expression:"(relationshipAll.filter(e => e.code == item.contactRelationCode)[0]||{}).name"}},[(index == 0)?[(_vm.Enum.contactRelationLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#820ECA","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])]:[(_vm.Enum.contactRelationLoading && _vm.Enum.otherContactRelationLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#820ECA","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])]],2),_c('mt-field',{attrs:{"label":_vm.$t('name'),"placeholder":_vm.$t('namePlaceholder'),"state":index >= 2? null: _vm.contactNameState(item.contactName)},nativeOn:{"!blur":function($event){_vm.BEHAVIOR_ADD({
            id: `P03_C${_vm.FillZero((index * 3) + 2)}_I_CONTACTNAME`,
            newValue: item.contactName
          })}},model:{value:(item.contactName),callback:function ($$v) {_vm.$set(item, "contactName", $$v)},expression:"item.contactName"}}),_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('#### #### #### #'),expression:"'#### #### #### #'"}],attrs:{"label":_vm.$t('phone'),"placeholder":_vm.$t('phonePlaceholder'),"type":"tel","state":index >= 2? null: _vm.maskedContactPhoneState(item.maskedContactPhone)},nativeOn:{"!blur":function($event){_vm.BEHAVIOR_ADD({
            id: `P03_C${_vm.FillZero((index * 3) + 3)}_I_CONTACTPHONE`,
            newValue: item.maskedContactPhone
          })}},model:{value:(item.maskedContactPhone),callback:function ($$v) {_vm.$set(item, "maskedContactPhone", $$v)},expression:"item.maskedContactPhone"}})],1)])}),_c('div',{staticClass:"add"},[_c('img',{attrs:{"src":require("../../assets/addContact.png")},on:{"click":function($event){return _vm.form.contactExpansion.push({
          contactRelationCode: '',
          contactName: '',
          maskedContactPhone: '',
        })}}})])],2),_c('div',{staticClass:"bottom"},[_c('mt-button',{attrs:{"type":"primary","size":"large"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("nextStep")))])],1),_c('popup-picker',{ref:"popupPicker"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }