import { mapMutations } from "vuex";
import {
  dictionary,
  EDUCATION,
  MARITAL_STATUS,
  DISTRICT,
  MONTHLY_INCOME,
  RELATIONSHIP,
  OTHER_RELATIONSHIP,
  BANK,
  RELIGION,
  SALARY_TYPE,
} from '../../utils/api'
let mixin = {
  components: {},
  data() {
    return {
      Enum: {
        educatedLoading: false,
        educated: [], // 学历
        marriedLoading: false,
        married: [], // 婚姻状况
        religionLoading: false,
        religion: [], // 宗教
        provLoading: false,
        prov: [], // 省市
        cityLoading: false,
        city: [], // 区
        monthlyIncomeLoading: false,
        monthlyIncome: [], // 平均月收入
        contactRelationLoading: false,
        contactRelation: [], // 亲属联系人
        otherContactRelationLoading: false,
        otherContactRelation: [], // 其他联系人
        bankLoading: false,
        bank: [], // 银行
        salaryTypeLoading: false,
        salaryType: [], // 工资类型
        payDateLoading: false,
        payDate: [], // 工资周期
      }
    }
  },
  created() {  },
  methods: {
    ...mapMutations(["SET_APPLY_ITEM"]),
    // 储存信息
    onStorageItem(key) {
      this.SET_APPLY_ITEM({
        key: key,
        value: this.form[key],
      });
    },
    // 打开弹窗
    onPopupPicker(options, callback) {
      this.$refs.popupPicker.openPicker({
        label: 'name',
        options,
        callback
      })
    },
    // 获取学历
    async GET_EDUCATION() {
      this.Enum.educatedLoading = true
      await this.getDictionary(EDUCATION).then(e => {
        this.Enum.educated = e
      })
      this.Enum.educatedLoading = false
    },
    // 获取婚姻状况
    async GET_MARITAL_STATUS() {
      this.Enum.marriedLoading = true
      await this.getDictionary(MARITAL_STATUS).then(e => {
        this.Enum.married = e
      })
      this.Enum.marriedLoading = false
    },
    // 获取宗教
    async GET_RELIGION() {
      this.Enum.religionLoading = true
      await this.getDictionary(RELIGION).then(e => {
        this.Enum.religion = e
      })
      this.Enum.religionLoading = false
    },
    // 获取地区(省市)
    async GET_DISTRICT_PROV() {
      this.Enum.provLoading = true
      await this.getDictionary(DISTRICT).then(e => {
        this.Enum.prov = e
      })
      this.Enum.provLoading = false
    },
    // 获取地区(区)
    async GET_DISTRICT_CITY(val) {
      this.Enum.cityLoading = true
      await this.getDictionary(dictionary + val).then(e => {
        this.Enum.city = e
      })
      this.Enum.cityLoading = false
    },
    // 获取平均月收入
    async GET_MONTHLY_INCOME() {
      this.Enum.monthlyIncomeLoading = true
      await this.getDictionary(MONTHLY_INCOME).then(e => {
        this.Enum.monthlyIncome = e
      })
      this.Enum.monthlyIncomeLoading = false
    },
    // 亲属联系人
    async GET_RELATIONSHIP() {
      this.Enum.contactRelationLoading = true
      await this.getDictionary(RELATIONSHIP).then(e => {
        this.Enum.contactRelation = e
      })
      this.Enum.contactRelationLoading = false
    },
    // 其他联系人
    async GET_OTHER_RELATIONSHIP() {
      this.Enum.otherContactRelationLoading = true
      await this.getDictionary(OTHER_RELATIONSHIP).then(e => {
        this.Enum.otherContactRelation = e
      })
      this.Enum.otherContactRelationLoading = false
    },
    // 获取银行
    async GET_BANK() {
      this.Enum.bankLoading = true
      await this.getDictionary(BANK).then(e => {
        this.Enum.bank = e
      })
      this.Enum.bankLoading = false
    },
    // 获取工资类型
    async GET_SALARY_TYPE() {
      this.Enum.salaryTypeLoading = true
      await this.getDictionary(SALARY_TYPE).then(e => {
        this.Enum.salaryType = e
      })
      this.Enum.salaryTypeLoading = false
    },
    // 获取工资周期
    async GET_PAY_DATE(val) {
      this.Enum.payDateLoading = true
      await this.getDictionary(dictionary + val).then(e => {
        this.Enum.payDate = e
      })
      this.Enum.payDateLoading = false
    },
    // 获取字典
    async getDictionary(url) {
      let list = []
      await this.$axios({
        method: "get",
        url: url,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            list = e.body
          }
        })
        .catch(() => {})
      return list
    },
  }
}
export default mixin;